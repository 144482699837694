.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  width: auto;
}

.wrapper__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(11, 51px);
  gap: 20px;

  overflow: auto;

  min-width: 350px;
  max-width: auto;
}
.wrapper__list::-webkit-scrollbar {
  width: 1px;
}

.wrapper__list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.wrapper__header-left {
  cursor: pointer;
  z-index: 1000;
  font-size: 40px;
  &:hover {
    color: hsl(168, 34%, 41%);
  }
}

.wrapper__header-right {
  cursor: pointer;
  font-size: 40px;
  z-index: 1000;
  &:hover {
    color: hsl(168, 34%, 41%);
  }
}

.wrapper__header {
  width: 100%;
  font-size: 20px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
}

.wrapper__header-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wrapper__header-input {
  height: 30px;
  padding: 0 10px;

  width: 200px;

  border: none;
  background-color: hsl(210, 4%, 95%);
  color: hsl(232, 10%, 55%);
  border-radius: 7px;
  &:focus {
    outline: none;
  }
}

.wrapper__header-page {
  margin-left: 20px;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    height: 100vh;
  }
}

@media screen and (max-width: 480px) {
  .wrapper__list {
    width: 100%;
    height: 100vh;
  }
}
